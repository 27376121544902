import React from 'react';

const isDebug = typeof window !== 'undefined' && window.wtlDebug === 'true';

if (isDebug) {
  require('antd/dist/antd.css');
}

require('../styles/ant.elements.css');
require('../styles/ant.editor.css');
require('../styles/ant.reset-typography.css');
require('../styles/wtl.grid.css');

export const AntWrapper = ({ children }) => (
  <div>
    {children}
  </div>
);
