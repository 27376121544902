import styled from 'styled-components';
import {
  Col, Divider
} from 'antd';

export const SimpleButton = styled.button`
  border: solid 1px #ccc;
  padding: 8px;
  margin: 2.5px 0;
  margin-right: 5px;
  line-height: 1em;
  box-sizing: border-box;

  ${p => p.background && `background-color: ${p.background};`}
  ${p => p.color && `color: ${p.color};`}

  &:not(:disabled):hover,
  &:not([disabled]):hover {
    border: solid 1px #1890ff;
    cursor: pointer;
  }

  &:disabled,
  &[disabled] {
    color: #eee;
    cursor: default;
  }

  ${p => p.toggled && `
    background-color: #1890ff;
    border: solid 1px #1890ff;
    color: #fff;
  `}
`;

export const BeautifulButton = styled.button`
  padding: 8px 16px;
  border: solid 1px #000;
  border-radius: 3px;
  font-size: 14px;
  color: #fff;
  text-shadow:
    -1px -1px 0px rgba(0, 0, 0, .5),
    1px -1px 0px rgba(0, 0, 0, .5),
    1px 1px 0px rgba(0, 0, 0, .5),
    -1px 1px 0px rgba(0, 0, 0, .5);
  background-color: #108ee9;
  box-shadow: 0px 2px 0px #000;
  outline: none;
  transition: background-color .3s ease;
  cursor: pointer;

  &:active,
  &:hover {
    background-color: #40afff;
  }
`;

export const SimpleInput = styled(Col)`
  position: relative;
  margin: 8px auto;

  & span {
    position: absolute;
    display: inline-block;
    top: 0;
    left: 0;
    font-size: 9px;
    font-weight: bold;
    transform: translateY(-50%);
  }

  & input,
  & select,
  & textarea {
    -webkit-appearance: none;
    appearance: none;
    width: 100%;
    min-height: 25px;
    margin: 0;
    margin-top: 8px;
    padding: 2px 8px;
    box-sizing: border-box;
    line-height: 1em;
    font-size: 11px;
    background-color: #fff;
    border: solid 1px #999;
    border-radius: 0;
    outline: none;

    &:disabled {
      border-color: #eee;
    }
  }

  & button {
    margin: 0;
    margin-top: 12px;
  }

  & > button {
    margin: 8px 0 0 0;
    padding: 6px 7px 7px 7px;
  }

  & > div {
    display: inline-block;
    margin-top: 8px;
  }

  & > .variable {
    position: absolute;
    display: inline-block;
    top: -8px;
    right: 4px;
    text-align: right;
    box-sizing: border-box;
    transition: all .2s ease;
    cursor: pointer;
    font-size: 9px;
    color: #777;
    transform: translateY(-50%);

    &:hover {
      color: #40afff;
    }
  }

  & textarea:focus + .variable,
  & select:focus + .variable,
  & input:focus + .variable {
    opacity: 0;
  }

  &.use-variable {
    & > input,
    & > select,
    & > textarea {
      color: #1890ff;
      border: solid 1px #1890ff;
    }

    & > span {
      &:after {
        content: ' (var)';
      }
    }
  }

  & > #brace-editor {
    width: auto !important;
  }
`;

export const SmallDivider = styled(Divider)`
  margin: 5px 0 !important;
`;

export const SmallTable = styled(Col)`
  position: relative;
  -webkit-appearance: none;
  appearance: none;
  width: calc(100% - 4px);
  box-sizing: border-box;
  margin: 2px;
  box-sizing: border-box;
  line-height: 1em;
  font-size: 11px;
  background-color: #fff;
  border: solid 1px #999;
  border-radius: 0;
  outline: none;
  padding: 0 !important;

  &[disabled] {
    border-color: #eee;
  }

  .header, .footer {
    display: block;
    width: 100%;
    height: 4px;
    margin: 0;
    background-color: #eee;
  }

  .header {
    border-bottom: solid 1px #999;
  }

  .footer {
    border-top: solid 1px #999;
  }

  .body, .actions {
    min-height: 25px;
    width: 100%;
    table-layout: fixed;
    cursor: default;

    td {
      box-sizing: border-box;
    }
  }

  .body {
    tr {
      border-bottom: solid 1px #ccc;
    }

    tr:last-child {
      border-bottom: none;
    }

    td {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding: 4px;
      border-right: solid 1px #eee;
    }

    td:last-child {
      border-right: none;
    }

    input, select {
      position: relative;
      display: inline-block;
      border: none;
      background: transparent;
      margin: 0;
      padding: 0;
      width: 100%;
      height: 100%;
      outline: none;
    }
  }

  .actions {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    overflow: hidden;

    &.always-on {
      border-top: solid 1px transparent;
      pointer-events: none;

      tr i {
        opacity: 1;
        transform: translateX(0px);
        pointer-events: all;
      }
    }

    tr {
      overflow: hidden;
      border-bottom: solid 1px transparent;

      &:hover {
        i {
          opacity: 1;
          transform: translateX(0px);
        }
      }

      i {
        box-sizing: border-box;
        opacity: 0;
        transform: translateX(10px);
        transition: opacity .1s ease, transform .1s ease;
        padding: 4px;
        background-color: #f5f5f5;
        color: #000;
        cursor: pointer;

        &:hover {
          color: #1890ff;
        }

        &[disabled] {
          color: #ccc !important;
          cursor: default;
        }
      }

      tr:last-child {
        border-bottom: none;
      }
    }

    td {
      text-align: right;
    }

    button {
      appearance: none;
      background: transparent;
      padding: 0;
      margin: 0;
      border: 0;
      outline: none;
    }
  }
`;